'use client';

import { AgentOptions } from '@newrelic/browser-agent/loaders/agent';
import { useEffect } from 'react';

type TNewRelic = {
  licenseKey?: string;
  applicationID?: string;
  accountID?: string;
  trustKey?: string;
};

export default function NewRelic({
  licenseKey,
  applicationID,
  accountID,
  trustKey,
}: TNewRelic) {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      // @ts-ignore
      import('@newrelic/browser-agent').then(({ BrowserAgent }) => {
        const options: AgentOptions = {
          init: {
            distributed_tracing: { enabled: true },
            privacy: { cookies_enabled: true },
            ajax: { deny_list: ['bam.nr-data.net'] },
          }, // NREUM.init
          info: {
            beacon: 'bam.nr-data.net',
            errorBeacon: 'bam.nr-data.net',
            licenseKey: licenseKey!,
            applicationID: applicationID!,
            sa: 1,
          }, // NREUM.info
          loader_config: {
            accountID: accountID,
            trustKey: trustKey,
            agentID: applicationID,
            licenseKey: licenseKey!,
            applicationID: applicationID!,
          }, // NREUM.loader_config
        };
        // @ts-ignore
        new BrowserAgent(options);
      });
    }
  }, [licenseKey, applicationID, accountID, trustKey]);
  return null;
}
